import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import arrowSvg from "../images/arrow-circle-right-solid.svg"

class BG3ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="BG3"
          description="View route information and buy your ticket for the BG3 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">BG3 service</h1>
              <p>Adel - Bradford Grammar School</p>
              <p>Bradford Grammar School - Adel</p>
              <p>For information on fares and passes please contact the school via email @ <a href="mailto:transport@bradfordgrammar.com">transport@bradfordgrammar.com</a></p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <iframe
                  title="BG3 route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=14Aqz0917cJxPaVrmSqyBHBue7llmVgc&hl=en&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>

                <p>
                  <strong>AM Towards</strong> Bradford Grammar School
                </p>
                <p>
                Otley Road, Leeds Road, Pool Bank New Road, Main Street, Pool Road, Cross green, Boroughgate, Kirkgate, Beech Hill, Westgate, Piper Lane, Bradford Lane, Otley Road, Bradford Road, Hollins Hill, Otley Road, Bradford Road, Keighley Road, Frizinghall Road, School Bus Bays.
                </p>

                <p>
                  <strong>PM From</strong> Bradford Grammar School
                </p>
                <p>
                School Bus Bays, Frizinghall Road, Keighley Road, Bradford Road, Otley Road, Hollins Hill, Bradford Road, Otley Road, Bradford Lane, Piper Lane, Westgate, Beech Hill, Kirkgate, Boroughgate, Cross lane, Pool Road, Main Street, Pool Bank New Road, Leeds Road, Otley Road.
                </p>

                <h4>Bus stop information</h4>
                <ul>
                  <li>Most stops listed are located at designated public bus stops however, a small number of stops (* marked with an asterisk), are not located at public bus stops; where this is the case, the exact location is noted.</li>
                  <li>Pupils are not permitted to board the bus at any other location than the stops listed. Pupils must arrive at their AM stop at least 5 minutes ahead of the times shown; the bus will depart on time and will not wait.</li>
                  <li>AM and PM times may vary due to unforeseen circumstances e.g. traffic, roadworks etc. Should a bus be delayed by more than 10 minutes, the school will make every effort to contact parents however, it is recommended that parents contact the bus company directly for updates</li>
                </ul>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Adel</td>
                      <td>A660 Otley Road/The Crescent</td>
                      <td>0719</td>
                    </tr>
                    <tr>
                      <td>Adel</td>
                      <td>A660 Otley Road/Kingsley Drive</td>
                      <td>0721</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/The Birches</td>
                      <td>0724</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/Tredgold Avenue</td>
                      <td>0727</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/Hall Rise</td>
                      <td>0728</td>
                    </tr>
                    <tr>
                      <td>Pool</td>
                      <td>A658 Main Street <span className="timetable-hint">Outside The White Hart Pub</span></td>
                      <td>0735</td>
                    </tr>
                    <tr>
                      <td>Otley</td>
                      <td>A659 Boroughgate/Wesley Street * <span className="timetable-hint">Pick up and drop off in white line bus bays</span></td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Menston</td>
                      <td>A65 Otley Road/A65 Bradford Road <span className="timetable-hint">Pick up at Chevin Avenue junction and drop off after roundabout</span></td>
                      <td>0747</td>
                    </tr>
                    <tr>
                      <td>Menston</td>
                      <td>A65 Bradford Road/High Royds Drive</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Guiseley</td>
                      <td>A6038 Bradford Road/Fieldhead Drive * <span className="timetable-hint">Pick up and drop off just after Fieldhead Drive; at the metal railings</span></td>
                      <td>0754</td>
                    </tr>
                    <tr>
                      <td>Guiseley</td>
                      <td>A6038 Bradford Road/Southway</td>
                      <td>0756</td>
                    </tr>
                    <tr>
                      <td>Baildon</td>
                      <td>A6038 Otley Road/Kirklands Lane <span className="timetable-hint">Opposite Halfway House Pub</span></td>
                      <td>0802</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable" style={{ fontSize: '0.9em' }}>
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Baildon</td>
                      <td>A6038 Otley Road/Kirklands Lane <span className="timetable-hint">Opposite Halfway House Pub</span></td>
                      <td>1625</td>
                    </tr>
                    <tr>
                      <td>Guiseley</td>
                      <td>A6038 Bradford Road/Southway</td>
                      <td>1631</td>
                    </tr>
                    <tr>
                      <td>Guiseley</td>
                      <td>A6038 Bradford Road/Fieldhead Drive * <span className="timetable-hint">Pick up and drop off just after Fieldhead Drive; at the metal railings</span></td>
                      <td>1633</td>
                    </tr>
                    <tr>
                      <td>Menston</td>
                      <td>A65 Bradford Road/High Royds Drive</td>
                      <td>1637</td>
                    </tr>
                    <tr>
                      <td>Menston</td>
                      <td>A65 Otley Road/A65 Bradford Road <span className="timetable-hint">Pick up at Chevin Avenue junction and drop off after roundabout</span></td>
                      <td>0740</td>
                    </tr>
                    <tr>
                      <td>Otley</td>
                      <td>A659 Boroughgate/Wesley Street * <span className="timetable-hint">Pick up and drop off in white line bus bays</span></td>
                      <td>0747</td>
                    </tr>
                    <tr>
                      <td>Pool</td>
                      <td>A658 Main Street <span className="timetable-hint">Outside The White Hart Pub</span></td>
                      <td>1652</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/Hall Rise</td>
                      <td>1659</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/Tredgold Avenue</td>
                      <td>1700</td>
                    </tr>
                    <tr>
                      <td>Bramhope</td>
                      <td>A660 Leeds Road/The Birches</td>
                      <td>1703</td>
                    </tr>
                    <tr>
                      <td>Adel</td>
                      <td>A660 Otley Road/Kingsley Drive</td>
                      <td>1705</td>
                    </tr>
                    <tr>
                      <td>Adel</td>
                      <td>A660 Otley Road/The Crescent</td>
                      <td>1707</td>
                    </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default BG3ServicePage
